<template>
  <transition name="expand">
    <div v-show="showAlert" data-cy="alert" :class="{'open': showAlert, 'closed': !showAlert}">
      <div class="alert mb-0 shadow-none" :class="alertBaseClasses">
        <div class="alert-content-wrapper w-100 d-flex">
          <span class="alert-icon align-middle" :class="alertIconDetails.classes">
            <fa-icon v-if="alertIconDetails.icon.length" :icon="alertIconDetails.icon"></fa-icon>
          </span>

          <span class="alert-title">{{ alert.title }}</span>
          <span class="alert-content w-100">
            <!--
              This slot allows alerts to optionally contain more complex contents (e.g.
              nuxt-link components). If the alert component instance has no wrapped
              contents, the slot defaults to the value of 'alert.content'.
            -->
            <slot>
              <div v-if="!alert.untrusted" v-html="alert.content"></div>
              <div v-else v-text="alert.content"></div>
            </slot>
          </span>
            <span class="alert-close ms-auto ps-3" v-if="alert.closeable" @click="closeAlert()">
              <fa-icon :icon="['far', 'xmark']"></fa-icon>
            </span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'Alert',
    props: ['textOnly', 'noMaxHeight', 'alwaysOpen', 'fixedAlertType'],
    emits: ['created'],
    data () {
      return {
        alert: {},
        defaultAlert: {
          closeable: false,
          title: '',
          content: '',
          open: false,
          untrusted: false,
        }
      }
    },
    created () {
      this.$emit('created')
    },
    computed: {
      showAlert () {
        return this.alwaysOpen || this.alert.open
      },
      alertType () {
        return this.fixedAlertType || this.alert.type
      },
      alertBaseClasses () {
        let classes = {}
        classes['alert-sm'] = this.alert.small
        classes['alert-lg'] = this.alert.large
        classes['alert-text-only'] = this.alert.textOnly
        classes['alert-wrapper'] = !this.alert.noMaxHeight
        classes['alert-' + this.alertType] = true
        return classes
      },
      alertIconDetails () {
        let classes = {}
        let icon = []
        if (this.alert.icon && this.alert.icon !== '') {
          icon = ['far', this.alert.icon]
          classes['me-2'] = true
        } else {
          classes['icon-24-' + this.alertType] = true
        }
        return { classes, icon }
      }
    },
    methods: {
      openDanger (options = { closeable: false, title: '', content: '', icon: '', untrusted: false, }) {
        this.alert = {
          ...this.defaultAlert,
          ...options,
          type: 'danger',
          open: true
        }
      },

      openSuccess  (options = { closeable: false, title: '', content: '', icon: '', untrusted: false, }) {
        this.alert = {
          ...this.defaultAlert,
          ...options,
          type: 'success',
          open: true
        }
      },

      openInfo (options = { closeable: false, title: '', content: '', icon: '', untrusted: false, }) {
        this.alert = {
          ...this.defaultAlert,
          ...options,
          type: 'info',
          open: true
        }
      },

      openWarning (options = { closeable: false, title: '', content: '', icon: '', untrusted: false, }) {
        this.alert = {
          ...this.defaultAlert,
          ...options,
          type: 'warning',
          open: true
        }
      },

      openPortal (options = { closeable: false, title: '', content: '', icon: '', untrusted: false, }) {
        this.alert = {
          ...this.defaultAlert,
          ...options,
          type: 'portal',
          open: true
        }
      },

      closeAlert () {
        this.alert.open = false
      }
    }
  }
</script>

<style lang="scss" scoped>

@import 'lendio-alerts';
// @import '@/node_modules/bootstrap/scss/bootstrap.scss';
// im not sure that we need any of the style below here:
.alert-wrapper {
  max-height: 200px;
  overflow: hidden;
  box-shadow: none;
}
.expand-enter-active, .expand-leave-active {
  overflow: hidden;
  transition: max-height 0.75s;
}
.expand-enter, .expand-leave-to {
  max-height: 0;
}
.alert-title {
  font-weight: 700;
  margin-right: 7px;
}
.alert-title:empty {
  display:none;
}
.alert-close {
  cursor: pointer;
}

/* Text Only Alerts */
.alert-text-only {
  border: 0;
  background-color: transparent;
}
.alert-text-only .alert-content-wrapper {
  text-align: center;
}

.alert-content {
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
}

// Temporary shift for new Alert type
.alert-portal {
  color: #253167;
  background-color: #f7f9ff;
  border-color: #afb1b5;
  max-width: 900px;
  margin: 0 16px;
}
.alert-portal hr {
  border-top-color: #afb1b5;
}
.alert-portal .alert-link {
  color: #182641;
}
</style>
